<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-row>
      <b-col cols="12">
        <web-heading />
      </b-col>
    </b-row>
    <b-card class="mb-0">
      <onboarding-wizard />
    </b-card>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

import WebHeading from '../utility/WebHeading.vue'
import OnboardingWizard from './OnboardingWizard.vue'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BRow,
    BCol,
    WebHeading,
    OnboardingWizard,
  },

}
</script>
