<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <div class="misc-inner p-2 p-sm-3 bg-orange-user">
      <div class="w-100 text-center">
        <b-img
          fluid
          :src="require('@/assets/images/logo/bisevowhite.png')"
          alt="logo img"
          width="150px"
          height="60px"
        />
      </div>
      <div class="w-100 text-center">
        <h3 class="mb-1 page-heading-2">
          Welcome to Bisevo Gamification
        </h3>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BImg,
} from 'bootstrap-vue'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
  },
  data() {
    return {
      logoImg: require('@/assets/images/logo/bisevowhite.png'),
      googleImg: require('@/assets/images/pages/google_badge.svg'),
      appleImg: require('@/assets/images/pages/ios_badge.svg'),
    }
  },
  computed: {
    imgLogoUrl() {
      return this.logoImg
    },
    imgAppleUrl() {
      return this.appleImg
    },
  },
}
</script>
